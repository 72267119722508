<template>
  <div>
    <v-tabs-items v-model="selectedAppBarTab">
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
      >
        <SplitListView
          :show-bottom-sheet="showBottomSheet"
        >
          <template v-slot:list>
            <ActionList
              :fixed-filters="fixedFiltersFromTabKey(tab.key)"
              :load-items-callback="getProfiles"
              :actions="actions"
              :call-to-actions="callToActions"
              :empty-text="$t('noProfilesFound')"
              :filters="filters"
            >
              <template v-slot:item="{ item, index }">
                <YessProfilesListItem
                  :profile="item"
                  :key="index"
                  @selectProfile="selectProfile"
                />
              </template>
            </ActionList>
            <YessProfilesList
              v-if="false"
              :profiles="profiles"
              @selectProfile="selectProfile"
            />
          </template>
          <template v-slot:view>
            <YessLinkDialog
              v-if="selectedProfile"
              :key="selectedProfile !== null ? selectedProfile.uuid : 'profile'"
              :yess-profile="selectedProfile"
              :organization="selectedProfileOrganization"
              :namespace="marketplaceNamespace"
              @closed="close"
            />
          </template>
        </SplitListView>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import YessLinkDialog from "@/components/yess/YessLinkDialog"
import YessProfilesList from "@/components/yess/YessProfilesList"
import SplitListView from "@/components/layouts/SplitListView"
import ActionList from "@/components/common/list/ActionList"
import YessProfilesListItem from "@/components/yess/YessProfilesListItem"

export default {
	name: 'YessProfilesView',
	data() {
		return {
			selectedProfile: null,
			selectedProfileOrganization: null,
      marketplaceNamespace: null,
      callToActions: [
        {
          title: this.$t('addProfile'),
          callback: this.showDialog
        }
      ],
		}
	},
	components: {
    YessProfilesListItem,
    ActionList,
		SplitListView,
		YessLinkDialog,
		YessProfilesList
	},
	computed: {
		tabs() {
      const allCount = Array.isArray(this.profiles) ? this.profiles.length : 0
      const activeCount = this.activeProfiles.length
      const inactiveCount = this.inactiveProfiles.length
			return [
				{
					title: this.$t('all'),
					key: 'all',
					badge: {
						color: 'blue',
            content: allCount
					}
				},
        {
          title: this.$t('active'),
          key: 'active',
          badge: {
            color: 'green',
            content: activeCount
          }
        },
        {
          title: this.$t('inactive'),
          key: 'inactive',
          badge: {
            color: 'red',
            content: inactiveCount
          }
        }
			]
		},
		selectedAppBarTab() {
			return this.$store.state.selectedAppBarTab
		},
		profiles() {
			return this.$store.state.yessProfiles
		},
    activeProfiles() {
      if(Array.isArray(this.profiles)) {
        return this.profiles.filter(profile => profile && profile.active === true)
      }
      return []
    },
    inactiveProfiles() {
      if(Array.isArray(this.profiles)) {
        return this.profiles.filter(profile => profile && profile.active === false)
      }
      return []
    },
    showBottomSheet: {
      get() {
        return Boolean(this.selectedProfile)
      },
      set() {
        this.selectedProfile = null
      }
    },
    actions() {
      return this.$store.getters.bulkOperationsByRoute('yess')
    },
    filters() {
      return [
        {
          key: 'type',
          label: this.$t('profileType'),
          type: 'select',
          value: null,
          operator: 'contains',
          items: this.appProfileTypes
        },
        {
          key: 'organization',
          label: this.$t('organization'),
          type: 'text',
          value: null,
          operator: 'contains'
        },
        {
          key: 'discountPercent',
          label: this.$t('discountPercent'),
          type: 'number',
          operator: 'equals',
          value: null
        }
      ]
    },
    appProfileTypes() {
      return this.$store.state.appProfileTypes
    },
	},
	methods: {
    fixedFiltersFromTabKey(key) {
      switch (key) {
        case 'active':
          return [{
            key: 'active',
            type: 'boolean',
            value: true,
            operator: 'equals'
          }]
        case 'inactive':
          return [{
            key: 'active',
            type: 'boolean',
            value: false,
            operator: 'equals'
          }]
        default:
          return []
      }
    },
		showDialog() {
			this.selectedProfile = {}
			this.selectedProfileOrganization = null
		},
		selectProfile(profile) {
			this.selectedProfile = profile
			this.selectedProfileOrganization = profile.organization
		},
		close() {
			this.selectedProfile = null
			this.selectedProfileOrganization = null
		},
		exportYessProfiles() {
			this.$store.commit('updateExportObjects', this.yessProfiles)
		},
    getProfiles() {
      return this.$store.dispatch('getMarketplaces').then(result => {
        if(Array.isArray(result) && result[0] && result[0].namespace) {
          this.marketplaceNamespace = result[0].namespace
          return this.$store.dispatch('getYessProfiles', result[0].namespace).then(result => {
            this.$store.dispatch('getOrganizations')
            this.$store.dispatch('getAppProfileTypes')
            this.$store.commit('updateAppBarTabs', this.tabs)
            return result
          })
        }
        return []
      })
    }
  },
	created() {
    this.getProfiles()
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
		this.$store.commit('updateAppBarFab', {
			color: 'pink',
			callback: this.showDialog
		})
		this.$store.commit('updateAppBarExport', {
			callback: this.exportYessProfiles
		})
	},
	mounted() {
		this.getProfiles()
		this.$store.commit('updateAppBarTabs', this.tabs)
		this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
		this.$store.commit('updateAppBarFab', {
			color: 'pink',
			callback: this.showDialog
		})
		this.$store.commit('updateAppBarExport', {
			callback: this.exportYessProfiles
		})
	},
	destroyed() {
		this.$store.commit('updateAppBarTabs', [])
	},
	watch: {
		yessProfiles(profiles) {
			if(this.selectedProfile) {
				const profile = profiles.find(p => p && p.slug === this.selectedProfile.slug)
				if(profile) {
					this.selectProfile(profile)
				}
			}
		}
	}
}
</script>
